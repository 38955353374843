<template>
  <div class="order-activity main-cnt">
    <div class="title">订单列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :needExport="true"
        :needPrint="true"
        :apiName="OrderApi.agentList"
        :columns="tableColumns"
        @detail="openDetailDialog"
        @export="handleExport"
        @print="handlePrint"
      >
        <template #sourceImg="{ row }">
          <el-image
            v-if="row.sourceFiles.length"
            style="width: 32px; height: 32px"
            :src="row.sourceFiles[row.sourceFiles.length - 1].file_url"
            :preview-src-list="[
              row.sourceFiles[row.sourceFiles.length - 1].file_url,
            ]"
            fit="cover"
          >
          </el-image>
        </template>
        <template #or_pay_status="{ row }">
          <span :style="'color:' + payStatusColors[row.or_pay_status]">{{
            payStatusOptions[row.or_pay_status]
          }}</span>
        </template>

        <template #work_status="{ row }">
          <span :style="'color:' + workStatusColors[row.work_status]">{{
            row.work_status_text
          }}</span>
        </template>
        <template #oat_pay_way="{ row }">
          <span :style="'color:' + 'var(--text-third-color)'">{{
            ["", "线下交易", "微信二维码"][row.oat_pay_way]
          }}</span>
        </template>

        <template #startrRefund="{ row }">
          <template v-if="row.is_init_refund == 1 && authData.indexOf('o_789OalFvc0XbJEpL4DqAMnd5RmoC') != -1">
            <el-button
              class="black-font-btn"
              @click="openStartrRefundDialog(row)"
              >发起退款</el-button
            >
          </template>
        </template>
        <template #confirmOrder="{ row }">
          <template v-if="row.is_confirm_pay == 1 && authData.indexOf('o_k1fA5oZi2NSbYxAMaaynmLz1T2y') != -1">
            <el-button class="theme-font-btn" @click="openSureOrderDialog(row)"
              >确认支付</el-button
            >
          </template>
        </template>
      </common-table>
    </div>
  </div>
  <confirm-dialog
    ref="sureOrderDialog"
    class="sure-voucher"
    @wConfirm="handleSureOrder"
    v-loading.fullscreen.lock="fullLoading"
    title="确认订单后不可撤回，若有凭证，请先上传凭证！"
  >
    <img-upload
      uploadTitle=""
      suggestText=""
      :fileList="photoAlbum"
      @uploadFile="uploadFile"
      @deleteFile="deleteFile"
    ></img-upload
  ></confirm-dialog>
  <AgentDetailDialog ref="agentDetailRef"></AgentDetailDialog>
  <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { BasicApi, OrderApi } from "@/plugins/api.js";
import AgentDetailDialog from "../components/AgentDetailDialog.vue";
import RefundDialog from "../components/RefundDialog.vue";
import imgUpload from "@/components/img-upload/img-upload.vue";
import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog.vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  exportExcel,
  print,
  getAgentWorkStatusColors,
  getPayStatusColors,
  getPayStatusOptions,
} from "@/utils/common.js";
const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "oat_sn",
    label: "订单号",
    color: "--text-third-color",
    minWidth: 270,
  },
  {
    prop: "oat_ticket_name",
    label: "门票名称",
  },
  {
    prop: "agent_name",
    label: "代理商名称",
    color: "--text-third-color",
    minWidth: 180,
  },
  {
    prop: "free_mag",
    label: "优惠",
    color: "--text-third-color",
    minWidth: 200,
  },
  {
    prop: "oat_price",
    label: "单价(元)",
    color: "--text-third-color",
  },
  {
    prop: "oat_num",
    label: "购买数量",
    minWidth: 80,
  },
  {
    prop: "oat_total_num",
    label: "到账数量",
    color: "--theme-color",
    minWidth: 80,
  },
  {
    prop: "or_allmoney",
    label: "总计金额",
    minWidth: 120,
  },
  {
    prop: "or_yhmoney",
    label: "优惠金额",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "or_money",
    label: "支付金额",
    minWidth: 120,
    color: "--theme-color",
  },
  {
    prop: "refund_money",
    label: "退款金额",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "or_pay_status",
    label: "支付状态",
    color: "--text-third-color",
    type: "customRender",
    minWidth: 80,
  },
  {
    prop: "work_status",
    label: "业务状态",
    color: "--text-third-color",
    type: "customRender",
    minWidth: 80,
  },
  {
    prop: "oat_pay_way",
    label: "支付方式",
    color: "--text-third-color",
    minWidth: 100,
    type: "customRender",
  },
  {
    prop: "or_pay_time",
    label: "支付时间",
    color: "--text-third-color",
    minWidth: 110,
  },
  {
    prop: "sourceImg",
    label: "支付凭证",
    color: "--text-third-color",
    minWidth: 110,
    type: "customRender",
  },

  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "确认支付",
        action: "confirmOrder",
        type: "customRender",
      },
      {
        name: "发起退款",
        action: "startrRefund",
        type: "customRender",
      },
      {
        name: "详情",
        action: "detail",
        token: "o_4nV7fxhCCLHXpc7o6705NprxT4cs",
      },
    ],
  },
]);

const filters = ref([
  {
    filterType: "search",
    name: "keyword",
    value: "",
    placeholder: "搜索订单号、门票名称",
  },
  {
    filterType: "select",
    name: "aid",
    value: "",
    placeholder: "请选择代理商",
    options: [],
    lab: "a_name",
    val: "a_id",
  },
]);
/** 获取代理商数据 */
const getAgentData = () => {
  BasicApi.agentOptions().then((res) => {
    if (res.Code === 200) {
      filters.value[1].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取代理商数据失败！";
      ElMessage.error(msg);
    }
  });
};

const workStatusColors = ref(getAgentWorkStatusColors());
const payStatusOptions = ref(getPayStatusOptions());
const payStatusColors = ref(getPayStatusColors());
/** 表格对象 */
const tableRef = ref(null);
function refreshTable() {
  tableRef.value.tableLoad();
}
onMounted(() => {
  getAgentData();
  refreshTable();
});

const agentDetailRef = ref(null);
function openDetailDialog(row) {
  agentDetailRef.value.openDialog(
    row.oat_sn,
    row.work_status,
    row.work_status_text
  );
} // 发起退款
const initRefundRef = ref(null);
const currentRefundSn = ref("");
const openStartrRefundDialog = (row) => {
  currentRefundSn.value = row.oat_sn;
  initRefundRef.value.openDialog(
    "发起退款",
    row.or_money,
    row.or_money,
    "agent"
  );
};
function handleInitRefund(data) {
  const parmas = {
    sn: currentRefundSn.value,
    ...data,
  };
  OrderApi.initRefund(parmas).then((res) => {
    initRefundRef.value.closeDialogLoading();
    if (res.Code === 200) {
      initRefundRef.value.closeDialog();
      refreshTable();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}

// 确认支付
const sureOrderDialog = ref(null);
const currentSureOrderSn = ref("");
const currentSureId = ref("");

function openSureOrderDialog(row) {
  store.dispatch("getQiniuData");
  currentSureOrderSn.value = row.oat_sn;
  currentSureId.value = row.oat_id;
  sureOrderDialog.value.show();
  photoAlbum.value = [];
}
// 上传凭证
const photoAlbum = ref([]);
const fullLoading = ref(false);
/** 多文件上传列表 */
const mulFileObj = ref({
  oat_id: "",
  sft_id: "",
  file_type: "",
  files: [],
});
function uploadFile(data) {
  photoAlbum.value = [
    {
      file_url: data.standard_url,
      sf_path: data.key,
    },
  ];
  fullLoading.value = true;
  mulFileObj.value.oat_id = currentSureId.value;
  mulFileObj.value.sft_id = 15;
  mulFileObj.value.file_type = 1;
  mulFileObj.value.files.push(data.key);
  // 去抖上传
  debounce(uploadSubmit, 300);
}
/** 定时器 id */
const timer = ref(null);
/** 去抖函数 */
const debounce = (fn, delay) => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(fn, delay);
};
/** 上传提交 */
const uploadedData = ref([]);
const uploadSubmit = () => {
  OrderApi.insertPhoto(mulFileObj.value).then((res) => {
    if (res.Code === 200) {
      uploadedData.value = res.Data;
      ElMessage.success("上传成功！");
    } else {
      let msg = res.Message ? res.Message : "上传失败！";
      ElMessage.error(msg);
    }
    fullLoading.value = false;
  });
  mulFileObj.value = {
    oat_id: "",
    sft_id: "",
    file_type: "",
    files: [],
  };
};
/** 文件删除 */
const deleteFile = (data) => {
  const item = uploadedData.value.find((el) => el.sf_path === data.sf_path);
  const parmas = { sf_id: item.sf_id };
  OrderApi.delPhoto(parmas).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("图片删除成功！");
      photoAlbum.value = [];
    } else {
      let msg = res.Message ? res.Message : "图片删除失败！";
      ElMessage.error(msg);
    }
  });
};
function handleSureOrder() {
  sureOrderDialog.value.isLoading = true;
  OrderApi.confirmAgentOrder({ sn: currentSureOrderSn.value }).then((res) => {
    sureOrderDialog.value.isLoading = false;
    if (res.Code === 200) {
      sureOrderDialog.value.close();
      refreshTable();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "获取酒店数据失败！";
      ElMessage.error(msg);
    }
  });
}
const propertiesConfig = ref([
  { field: "oat_sn", displayName: "订单号" },
  { field: "oat_ticket_name", displayName: "门票名称" },
  { field: "agent_name", displayName: "代理商名称" },
  { field: "free_mag", displayName: "优惠" },
  { field: "oat_price", displayName: "单价(元)" },
  { field: "oat_num", displayName: "购买数量" },
  { field: "oat_total_num", displayName: "实际到账数量" },
  { field: "or_allmoney", displayName: "总计金额" },
  { field: "or_yhmoney", displayName: "优惠金额" },
  { field: "or_money", displayName: "支付金额" },
  { field: "pay_status_text", displayName: "支付状态" },
  { field: "work_status_text", displayName: "业务状态" },
  { field: "pay_way_text", displayName: "支付方式" },
  { field: "or_pay_time", displayName: "支付时间" },
]);
function processData(data) {
  // data.length &&
  //   data.forEach((el) => {
  //     el.moduleTitle = el.mat_first_name + "-" + el.mat_second_name;
  //     el.validity =
  //       el.stime === el.etime ? el.stime + "当天" : el.stime + "至" + el.etime;
  //   });
  return data;
}
function handlePrint(data) {
  print("代理商订单", processData(data), propertiesConfig.value);
}
function handleExport(data) {
  try {
    exportExcel("代理商订单", processData(data), propertiesConfig.value);
    ElMessage.success({
      message: "导出成功！",
    });
  } catch (e) {
    ElMessage.error({
      message: "导出失败！",
    });
  }
}
</script>
<style lang="scss">
.sure-voucher {
  .img-upload ul {
    text-align: center;
    margin-top: 20px;
  }
}
.order-activity {
  font-family: "Source Han Sans CN";

  .el-input-group__append {
    border: none;
    background-color: var(--search-uncheck-bg-color) !important;
    color: var(--text-gray-color);
  }
  .content {
    padding: 15px 20px 20px;
  }
  .font-gray {
    color: var(--text-gray-color);
  }
  .font-999 {
    color: var(--text-third-color);
  }
  .refund-money {
    width: 100%;
    padding: 20px 0;
    background-color: var(--theme-bg-color);
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    color: var(--text-gray-color);
    > div {
      color: var(--text-color);
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
