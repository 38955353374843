<template>
  <div class="agent-order-detail">
    <w-dialog
      ref="detailDialogRef"
      title="代理商订单详情"
      width="78%"
      btnWidth="140px"
      top="2.8vh"
      :hideFooter="true"
      class="order-detail"
    >
      <el-descriptions :column="4" class="basic-info" border>
        <el-descriptions-item
          v-for="(el, index) in basicInfo"
          :key="index"
          :label="el.label"
          ><span
            :style="{
              color: el.color ? el.color[el.key] : '',
            }"
            >{{ el.value }}</span
          ></el-descriptions-item
        >
      </el-descriptions>
      <div class="font-999">景点分账明细</div>
      <common-table
        ref="peopleTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="tableData"
        :columns="peopleTableColumns"
      >
      </common-table>
      <br />
      <div class="font-999">退款明细</div>
      <common-table
        ref="refundTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="refundData"
        :columns="refundTableColumns"
      >
      </common-table>
      <br />
      <div class="font-999">已售信息</div>
      <common-table
        ref="tableRef"
        tableHeight="200"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :apiName="OrderApi.agentTourist"
        :extraParame="{ sn: snId }"
        :columns="tableColumns"
      >
        <template #pass_status="{ row }">
          <span :style="'color:' + passStatusColors[row.pass_status]">{{
            row.pass_status_text
          }}</span>
        </template>
      </common-table>
    </w-dialog>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import { OrderApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  getPassStatusColors,
  getPayStatusColors,
  getAgentWorkStatusColors,
} from "@/utils/common.js";
export default {
  emits: ["submit"],
  components: {},
  setup() {
    const detailDialogRef = ref(null);
    const passStatusColors = ref(getPassStatusColors());
    const workStatusColors = ref(getAgentWorkStatusColors());
    const basicInfo = ref({});
    const detailInfo = ref({});
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    const authInfo = ref({});
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const tableData = ref([]);
    const peopleTableColumns = ref([
      {
        prop: "name",
        color: "--text-third-color",
        label: "景区名称",
      },
      {
        prop: "money",
        color: "--text-third-color",
        label: "具体金额",
      },
    ]);
    const tableColumns = ref([
      {
        prop: "ot_sn",
        color: "--text-third-color",
        label: "订单号",
        minWidth: 120,
      },
      {
        prop: "otr_realname",
        color: "--text-third-color",
        label: "游客名",
      },
      {
        prop: "otr_mobile",
        color: "--text-third-color",
        label: "手机号",
      },
      {
        prop: "otr_idcard",
        color: "--text-third-color",
        label: "身份证",
        minWidth: 120,
      },
      {
        prop: "pass_status",
        color: "--text-third-color",
        type: "customRender",
        label: "状态",
      },
      {
        prop: "ot_sdate",
        label: "使用期限",
        color: "--text-third-color",
        prop2: "ot_edate",
        type: "connect",
        connectText: "至",
      },
      {
        prop: "ot_ctime",
        color: "--text-third-color",
        label: "下单时间",
      },
      {
        prop: "user_time",
        color: "--text-third-color",
        label: "使用时间",
      },
    ]);
    const filters = ref([
      {
        filterType: "search",
        name: "keywords",
        value: "",
        placeholder: "搜索旅客名、订单号、身份证号搜索",
      },
    ]);
    const refundTableRef = ref(null);
    const refundData = ref([]);
    const refundTableColumns = ref([
      {
        prop: "refund_sn",
        color: "--text-third-color",
        label: "退款单号",
      },
      {
        prop: "refund_money",
        label: "退款金额",
        color: "--text-third-color",
      },
      {
        prop: "refund_payway_name",
        color: "--text-third-color",
        label: "退款方式",
      },
      {
        prop: "refund_confirm_user",
        color: "--text-third-color",
        label: "退款确认人",
      },
      {
        color: "--text-third-color",
        prop: "refund_confirm_time",
        label: "退款时间",
      },
      {
        color: "--text-third-color",
        prop: "refund_remark",
        label: "退款原因",
      },
    ]);

    const payStatusColors = ref(getPayStatusColors());
    function getDetail(type, value, text) {
      OrderApi.agentDetail({ sn: snId.value }).then((res) => {
        if (res.Code === 200) {
          detailInfo.value = res.Data;
          tableData.value = res.Data.ledger_account || [];
          refundData.value = res.Data.refund_list || [];
          basicInfo.value = [
            {
              label: "门票名称",
              value: detailInfo.value.oat_ticket_name
                ? detailInfo.value.oat_ticket_name
                : "-",
            },

            {
              label: "门票类型",
              value: detailInfo.value.ticket_type_name
                ? detailInfo.value.ticket_type_name
                : "-",
            },

            {
              label: "所属景区",
              value: detailInfo.value.scenic_name
                ? detailInfo.value.scenic_name
                : "-",
            },
            {
              label: "订单号码",
              value: detailInfo.value.oat_sn ? detailInfo.value.oat_sn : "-",
            },
            {
              label: "单价",
              value: detailInfo.value.oat_price
                ? detailInfo.value.oat_price
                : "-",
            },

            {
              label: "支付金额",
              value: detailInfo.value.oat_money
                ? detailInfo.value.oat_money + "元"
                : "-",
            },

            {
              label: "支付方式",
              value:
                detailInfo.value.oat_pay_way === 1
                  ? "线下交易"
                  : detailInfo.value.pay_way_text,
            },
            {
              label: "支付时间",
              value: detailInfo.value.or_pay_time
                ? detailInfo.value.or_pay_time
                : "-",
            },
            {
              label: "支付状态",
              value: detailInfo.value.pay_status_text
                ? detailInfo.value.pay_status_text
                : "-",
              color: payStatusColors.value,
              key: detailInfo.value.or_pay_status,
            },

            {
              label: "业务状态",
              value: text ? text : "-",
              color: workStatusColors.value,
              key: value,
            },
            {
              label: "优惠",
              value: detailInfo.value.free_mag
                ? detailInfo.value.free_mag
                : "-",
            },
            {
              label: "使用期限",
              value: detailInfo.value.oat_begin_at
                ? detailInfo.value.oat_begin_at +
                  "至" +
                  detailInfo.value.oat_expire_at
                : "-",
            },
            {
              label: "购买数量",
              value: detailInfo.value.oat_num ? detailInfo.value.oat_num : "-",
            },

            {
              label: "到账数量",
              value: detailInfo.value.oat_total_num ? detailInfo.value.oat_total_num : "-",
            },

            {
              label: "已售数量",
              value: detailInfo.value.sold_num
                ? detailInfo.value.sold_num
                : "-",
            },
            {
              label: "未售数量",
              value: detailInfo.value.remaining_num
                ? detailInfo.value.remaining_num
                : "-",
            },
          ];
          type == 1 ? detailDialogRef.value.show() : "";
          nextTick(() => {
            if (tableData.value && tableData.value.length != 0) {
              peopleTableRef.value.tableLoad();
            }
            if (refundData.value.length != 0) {
              refundTableRef.value.tableLoad();
            }
          });
          nextTick(() => {
            tableRef.value.tableLoad();
          });
        } else {
          let msg = res.Message ? res.Message : "获取详情数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    const snId = ref(null);
    const peopleTableRef = ref(null);
    const tableRef = ref(null);
    function openDialog(id, value, text) {
      snId.value = id;
      getDetail(1, value, text);
    }
    function closeDialog() {
      detailDialogRef.value.close();
    }

    return {
      closeDialog,
      basicInfo,
      detailDialogRef,
      openDialog,
      detailInfo,
      authInfo,
      authData,
      peopleTableRef,
      tableData,
      peopleTableColumns,
      snId,
      OrderApi,
      filters,
      tableRef,
      tableColumns,
      passStatusColors,
      refundTableRef,
      refundData,
      refundTableColumns,
    };
  },
};
</script>

<style lang="scss">
.agent-order-detail {
  .order-detail {
    .basic-info {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 16px 30px 12px 30px;
      background-color: var(--search-uncheck-bg-color);
      .el-descriptions__body {
        border: none;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__label,
        .el-descriptions__content {
          border: 1px solid transparent;
          background-color: var(--search-uncheck-bg-color);
        }
        .el-descriptions__cell {
          padding: 0 0 4px 0;
        }
        .el-descriptions__label {
          width: 64px;
          color: var(--text-gray-color);
          font-weight: normal;
          text-align: right;
          padding-right: 6px;
        }
        .el-descriptions__content {
          color: var(--text-color);
          min-width: 100px;
        }
      }
    }
    .refund-desc {
      > span {
        color: var(--text-third-color);
      }
      .info {
        background-color: var(--search-uncheck-bg-color);
        padding: 16px 30px;
        border-radius: 4px;
        color: var(--text-gray-color);
      }
    }
  }
}
</style>
